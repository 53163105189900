<template>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <!-- modal for add new cert -->
                <b-overlay :show="register_show_overlay" rounded="sm">
                    <b-modal
                        id="modal-create"
                        cancel-variant="secondary"
                        ok-title="Add"
                        cancel-title="Cancel"
                        centered
                        title="Add new certificate"
                        @ok="addCert"
                    >
                        <b-form>
                            <b-form-group label="Domains" label-for="largeInput">
                                <b-form-tags
                                    input-id="tags-separators"
                                    v-model="newCertDomains"
                                    tag-variant="primary"
                                    separator=" ,;"
                                    placeholder="Enter domains separated by space, comma or semicolon"
                                ></b-form-tags>
                                <b-form-invalid-feedback v-bind:[certDomainsState]="certDomainsValidation">
                                    domain format error
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group label="Chain file">
                                <b-input-group class="mt-3">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="FileIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file
                                        v-model="newCertFile"
                                        placeholder="Choose a cert file or drop it here..."
                                        drop-placeholder="Drop chain file here..."
                                        v-on:change="fileInput($event, getCertContent)"
                                    />
                                </b-input-group>
                            </b-form-group>

                            <b-form-group label="Key file">
                                <b-input-group class="mt-3">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="FileIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file
                                        v-model="newKeyFile"
                                        placeholder="Choose a key file or drop it here..."
                                        drop-placeholder="Drop key file here..."
                                        v-on:change="fileInput($event, getKeyContent)"
                                    />
                                </b-input-group>
                            </b-form-group>
                        </b-form>
                    </b-modal>
                </b-overlay>
            </div>
        </div>

        <!-- table -->
        <div class="col-12">
            <b-card header="Manager Certificate">
                <div class="pr-3 pb-3 pl-3">
                    <b-row class="mb-3">
                        <b-col>
                            <b-button class="mr-3" variant="primary" v-b-modal.modal-create @click="clickAddCert"> Add Certificate </b-button>

                            <VDropdown>
                                <b-button variant="secondary" class="mr-3 ml-2">
                                    <feather-icon icon="SearchIcon" class="mr-2" size="15" />
                                    <span class="align-middle">Search-items</span>
                                </b-button>
                                <!-- This will be the content of the popover -->
                                <template #popper>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <b-form-input placeholder="domain pattern" v-model="queryDomainPattern" />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1 mt-3">
                                        <b-col>
                                            <b-button variant="secondary" v-close-popper="true" @click="search"> Search</b-button>
                                            <b-button class="ml-2" variant="secondary" @click="clearQueryCondition"> Clear</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </VDropdown>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="pagination"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->
                                <span v-if="props.column.field === 'related_domain'">
                                    <b-badge v-for="domain in props.row[props.column.field]" variant="primary" class="ml-1 mr-1" :key="domain">
                                        {{ domain }}</b-badge
                                    >
                                </span>

                                <span v-else-if="props.column.field === 'tokens_array'" >
                                    <span v-for="tokenInfo in props.row[props.column.field]" variant="primary" :key="tokenInfo.key">
                                        <b-row class="mt-1 ml-3" style="">
                                            <b-badge variant="primary">{{ tokenInfo.token }}</b-badge> &ensp;:&ensp;
                                            <b-badge variant="primary">{{ tokenInfo.description }}</b-badge></b-row
                                        ></span
                                    >
                                </span>

                                <span v-else-if="props.column.field === 'expiration_time'">
                                    <b-badge class="ml-1 mr-1" :variant="dateClass(props.row.expiration_time)">
                                        {{ parseTime(props.row.expiration_time) }}</b-badge
                                    >
                                </span>

                                <span v-else-if="props.column.field === 'action1'">
                                    <b-button variant="primary" @click="open_row_view_edit(props.row.originalIndex)">Edit/Delete</b-button>
                                    <b-button class="ml-3" variant="primary" @click="applyCert(props.row)">Apply Cert</b-button>
                                    <b-button v-if="props.row.Hash !== ''" class="ml-3" variant="primary" @click="showCert(props.row.originalIndex)"
                                        >Show Cert</b-button
                                    >
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- modal_row_view_update-->
                    <b-modal
                        id="modal_row_view_update"
                        size="lg"
                        @hide="handleHide"
                        title="view/edit"
                        cancel-variant="secondary"
                        cancel-title="Close"
                        centered
                    >
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <b-form>
                                <b-form-group>
                                    <label>Domains:</label>

                                    <b-form-tags
                                        input-id="tags-separators"
                                        v-model="focus.related_domain"
                                        separator=" ,;"
                                        tag-variant="primary"
                                        placeholder="Enter domains separated by space, comma or semicolon"
                                        disabled
                                    ></b-form-tags>
                                </b-form-group>

                                <b-form-group label="Chain file">
                                    <b-input-group class="mt-3">
                                        <b-input-group-prepend is-text>
                                            <feather-icon icon="FileIcon" />
                                        </b-input-group-prepend>
                                        <b-form-file
                                            v-model="newCertFile"
                                            placeholder="Choose a cert file or drop it here..."
                                            drop-placeholder="Drop chain file here..."
                                            v-on:change="fileInput($event, getCertContent)"
                                        />
                                    </b-input-group>
                                </b-form-group>

                                <b-form-group label="Key file">
                                    <b-input-group class="mt-3">
                                        <b-input-group-prepend is-text>
                                            <feather-icon icon="FileIcon" />
                                        </b-input-group-prepend>
                                        <b-form-file
                                            v-model="newKeyFile"
                                            placeholder="Choose a key file or drop it here..."
                                            drop-placeholder="Drop key file here..."
                                            v-on:change="fileInput($event, getKeyContent)"
                                        />
                                    </b-input-group>
                                </b-form-group>

                                <b-form-group label="Cert tokens">
                                    <b-row v-for="(value,index) in tokens_array" :key="value.token" class="mt-1">
                                        <b-col cols="6">
                                            <b-form-input v-model="value.token" placeholder="token"  disabled/>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input v-model="value.description" placeholder="description"    />
                                        </b-col>
                                        <b-col cols="3">
                                            <b-button variant="primary" @click="genToken(index)">gen token</b-button>
                                            <b-button class="ml-1" variant="secondary" @click="deleteTokenRecord(index)"><feather-icon icon="XIcon" size="15" /></b-button>
                                        </b-col>
                                    </b-row>

                                    
                                </b-form-group>

                                <b-button variant="primary" @click="addTokenRecord">add new token pair</b-button>
                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left p-0">
                                    <b-button variant="danger" @click="deleteCert">Delete</b-button>
                                </b-col>
                                <b-col cols="8" class="text-right p-0">
                                    <b-button variant="primary" class="mr-3" @click="updateCert">Update</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('modal_row_view_update')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>

                    <!-- show cert -->
                    <b-modal id="modal-showcert" title="cert content" size="lg" ok-title="OK" ok-only centered>
                        <b-form>
                            <b-form-group label="Crt content" label-for="cert">
                                <b-form-textarea
                                    id="certArea"
                                    v-model="this.focus.crt_content"
                                    placeholder="cert"
                                    rows="10"
                                    max-rows="10"
                                    disabled
                                ></b-form-textarea>
                            </b-form-group>

                            <b-form-group label="Key content" label-for="key">
                                <b-form-textarea
                                    id="keyArea"
                                    v-model="this.focus.key_content"
                                    placeholder="key"
                                    rows="10"
                                    max-rows="10"
                                    disabled
                                ></b-form-textarea>
                            </b-form-group>
                        </b-form>
                    </b-modal>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormFile,
    BFormTags,
    BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormFile,
        BFormTags,
        BFormTextarea,
        VueGoodTable,
    },
    computed: {
        certDomainsState() {
            return this.newCertDomains.length != 0 ? "state" : null;
        },
        certDomainsValidation() {
            for (let index = 0; index < this.newCertDomains.length; index++) {
                const domain = this.newCertDomains[index];
                let match = this.$tools.validator.validateCertDomain(domain);
                if (match === false) {
                    return false;
                }
                if (domain.includes(" ")) {
                    return false;
                }
            }
            return true;
        },
    },

    methods: {
        dateClass(unixTimeStamp) {
            let nowTimeStamp = moment().unix();
            let gap = unixTimeStamp - nowTimeStamp;
            if (gap < 3600 * 24 * 15) {
                return "danger";
            } else if (gap < 3600 * 24 * 29) {
                return "warning";
            } else {
                return "success";
            }
        },

        clickAddCert() {
            (this.newCertFile = null), (this.newKeyFile = null), (this.newCertContent = "");
            this.newKeyContent = "";
            this.newCertDomains = [];
        },

        randomString(length) {
            var str = "abcdefghijklmnopqrstuvwxyz";
            var result = "";
            for (var i = length; i > 0; --i) result += str[Math.floor(Math.random() * str.length)];
            return result;
        },

        genToken(index) {
            this.tokens_array[index].token = this.randomString(24);
        },
        deleteTokenRecord(index) {
            this.tokens_array.splice(index, 1);
        },
        addTokenRecord() {
            this.tokens_array.push({ token: this.randomString(24), description: "" });
        },


        parseTime(timeStamp) {
            return moment.unix(timeStamp).format("YYYY-MM-DD");
        },
        clearQueryCondition() {
            this.queryDomainPattern = "";
        },

        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        open_row_view_edit(rowid) {
            this.focus = JSON.parse(JSON.stringify(this.row_data[rowid]));
            this.updateDomainExpirationTime = new Date(this.focus.expiration_time * 1000);
            this.tokens_array = this.focus.tokens_array;
            this.$bvModal.show("modal_row_view_update");
        },

        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        async search() {
            this.$refs["remote_q_table"].reset();
            this.offset = 0;
            await this.refreshTable();
        },

        async refreshTable() {
            this.table_show_overlay = true;
            let resp = await this.$api.cert.queryCert(this.queryDomainPattern, this.limit, this.offset, this.$store.state.auth.my_web_token);
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            for (let i = 0; i < resp.result.cert_list.length; i++) {
                resp.result.cert_list[i].tokens_array = [];
                for (const key in resp.result.cert_list[i].tokens_map) {
                    resp.result.cert_list[i].tokens_array.push({ token: key, description: resp.result.cert_list[i].tokens_map[key] });
                }
            }

            this.row_data = resp.result.cert_list;
            this.totalRows = resp.result.count;
        },

        async updateCert() {
            if (this.newCertContent === "" && this.newKeyContent !== "") {
                bvModalEvt.preventDefault();
                this.$bvToast.toast("cert file empty", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (this.newCertContent !== "" && this.newKeyContent === "") {
                bvModalEvt.preventDefault();
                this.$bvToast.toast("key file empty", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let cert = this.newCertContent === "" ? null : this.newCertContent;
            let key = this.newKeyContent === "" ? null : this.newKeyContent;

            let tokens_map={}
            for (let i = 0; i < this.tokens_array.length; i++) {
                tokens_map[this.tokens_array[i].token]=this.tokens_array[i].description
                
            }

            let resp = await this.$api.cert.updateCert(this.focus.id, cert, key, tokens_map, this.$store.state.auth.my_web_token);

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("cert updated", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
            this.$bvModal.hide("modal_row_view_update");
            (this.newCertFile = null), (this.newKeyFile = null), (this.newCertContent = "");
            this.newKeyContent = "";
            this.newCertDomains = [];
            this.tokens_array = [];
        },

        async deleteCert() {
            let value = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete cert [${this.focus.related_domain}] ?`, {
                title: "Delete Certificate",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (value) {
                let resp = await this.$api.cert.deleteCert(this.focus.id, this.$store.state.auth.my_web_token);
                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("certificate deleted", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                this.refreshTable();
                this.$bvModal.hide("modal_row_view_update");
            }
        },

        async showCert(rowid) {
            this.focus = this.row_data[rowid];
            this.$bvModal.show("modal-showcert");
        },

        async applyCert(row) {
            // console.log(row);
            let value = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to apply new certificate for [${row.related_domain}]`, {
                title: "Apply new certificate",
                okVariant: "success",
                okTitle: "Apply",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (value) {
                this.table_show_overlay = true;
                let resp = await this.$api.cert.applyCert(row.id, this.$store.state.auth.my_web_token);
                this.table_show_overlay = false;
                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("success", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                this.refreshTable();
            }
        },

        fileInput(event, callback) {
            var reader = new FileReader();
            reader.readAsText(event.target.files[0]);
            reader.onload = function (e) {
                let fileContent = e.target.result;
                if (callback && typeof callback === "function") {
                    callback(fileContent);
                }
            };
        },

        getCertContent(fileContent) {
            this.newCertContent = fileContent;
        },
        getKeyContent(fileContent) {
            this.newKeyContent = fileContent;
        },

        async addCert(bvModalEvt) {
            if (this.certDomainsValidation == false) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast("cert domains format error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.register_show_overlay = true;

            let resp = await this.$api.cert.addCert(
                this.newCertDomains,
                this.newCertContent,
                this.newKeyContent,
                this.$store.state.auth.my_web_token
            );
            this.register_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("cert added", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            (this.newCertFile = null), (this.newKeyFile = null), (this.newCertContent = "");
            this.newKeyContent = "";
            this.newCertDomains = [];
            this.refreshTable();
        },
    },

    mounted() {
        this.refreshTable();
    },
    data() {
        return {
            //for table
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "RelatedDomain",
                    field: "related_domain",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Hash",
                    field: "hash",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "User ID",
                    field: "user_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Expiration",
                    field: "expiration_time",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Tokens",
                    field: "tokens_array",
                    tdClass: "text-center",
                    thClass: "text-center",
                    width: "350px",
                },
                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                    width: "330px",
                },
            ],
            pagination: {
                enabled: true,
                mode: "pages",
                perPage: 10,
                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                setCurrentPage: 1,
            },
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            //for update and delete
            focus: {
                id: 0,
            },

            table_show_overlay: true,
            modal_row_view_overlay: false,
            register_show_overlay: false,

            queryDomainPattern: "",

            newCertDomains: [],
            newCertFile: null,
            newKeyFile: null,
            newCertContent: "",
            newKeyContent: "",
            tokens_array: [],
        };
    },
};
</script>
